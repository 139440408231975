
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="card">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-4" id="invoice" role="tabpanel" aria-labelledby="invoice-tab">
          <div class="d-sm-flex mb-5" data-view="print"><span class="m-auto"></span>
            <button class="btn btn-primary mb-sm-0 mb-3 print-invoice" @click="printInvoice">Download Statement</button>
          </div>
          <!-- -===== Print Area =======-->
          <div id="print-area">

            <div class="row">
              <div class="col-md-12 text-center">
                <img src="@/assets/images/sarit_logo.png" style="width: 150px;height: 70px" class="img img-responsive" />
                <br/>
                <br/>
                <h3>Loyalty Points Statement </h3>
              </div>
            </div>

            <div class="row">
               <div class="col-md-12 text-center">
                   <p>
                     Phone: +254 111 040 600 | Email: info@saritcentre.com | Location: Westlands, Nairobi

                   </p>
               </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <p><strong>Statement No: </strong> {{ invoice.invoiceNum }} </p>
                <p><strong>Merchant: </strong> {{ invoice.outlet.name}} </p>
                <p><strong>Net Value in KES: </strong> {{ invoice.invoiceAmount | number('0,0') }}</p>
                <p><strong>VAT @ 16%: </strong> {{ invoice.totalTax | number('0,0') }}</p>
                <p><strong>Gross Amount (in KES): </strong> {{ invoice.invoiceAmount + invoice.totalTax | number('0,0') }}</p>
              </div>
              <div class="col-md-6 text-sm-right">
                <p><strong>Dated: </strong> {{ invoice.invoiceDate }}</p>

              </div>
            </div>
            <div class="mt-3 mb-4 border-top"></div>

            <div class="row">
              <div class="col-md-12 table-responsive">
                <table class="table table-hover mb-4">
                  <thead class="bg-gray-300">
                  <tr>
                    <th scope="col">Txn Date</th>
                    <th scope="col">Txn No</th>
                    <th scope="col">Rcpt No</th>
                    <th scope="col">Card No</th>
                    <th scope="col">Amount (in KES)</th>
                    <th scope="col">Points</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoiceItem in invoiceItems">

                      <td>
                        {{ invoiceItem.transaction.transactionDate }}
                      </td>
                      <td>
                        {{ invoiceItem.transaction.transactionId }}
                      </td>
                      <td>
                        {{ invoiceItem.transaction.referenceNo }}
                      </td>
                      <td>
                        {{ invoiceItem.transaction.customer.loyaltyId }}
                      </td>
                      <td>
                        {{ invoiceItem.transaction.amount | number('0,0') }}
                      </td>
                      <td>
                        <span v-if="invoiceItem.loyaltyPoints">
                          {{ invoiceItem.loyaltyPoints | number('0,0') }}
                        </span>
                        <span v-else>
                          {{ invoiceItem.transaction.loyaltyPoints | number('0,0') }}
                        </span>

                      </td>

                    </tr>
                    <tr>
                      <th colspan="5">Net Value in KES:</th>
                      <th> {{ invoice.invoiceAmount | number('0,0') }}</th>
                    </tr>
                    <tr v-if="invoice.totalTax > 0">
                      <th colspan="5">VAT @ 16%:</th>
                      <th> {{ invoice.totalTax | number('0,0') }}</th>
                    </tr>
                    <tr>
                      <th colspan="5">Gross Amount (in KES)</th>
                      <th> {{ invoice.invoiceAmount + invoice.totalTax | number('0,0') }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                  <ul class="pagination justify-content-center">
                    <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                    <li v-for="page in pagesNumber"
                        v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                    <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>

          </div>
          <!-- ==== / Print Area =====-->
        </div>

      </div>
    </div>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'
  import VueHtmlToPaper from 'vue-html-to-paper';

export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      invoices:[],
      start_date:"all",
      end_date:"all",
      invoiceItems:[],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
      invoice:{
        created: "",
        id: "",
        invoiceAmount: "",
        invoiceDate: "",
        invoiceEndDate: "",
        invoiceNum: "",
        invoiceStartDate: "",
        outlet: {
          name:"",
          phoneNumber:"",
          email:""
        },
        status: "",
        totalTax: "",
        updated: ""
      }
    };
  },
  computed:{
    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    },
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  mounted (){
    this.getInvoiceDetailsById();
    this.getInvoiceItems();

  },
  methods: {
    ...mapActions(["getInvoiceById","downloadInvoiceStatement","getInvoiceItemsByInvoice"]),
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getInvoiceItems();
    },
    printInvoice(){
      let self = this;

      self.isLoading = true;

      this.downloadInvoiceStatement({invoiceId: this.$route.params.id})

          .then(function (invoice) {

            self.isLoading = false;

            if(invoice.statusCode == 200){
              location.replace(invoice.message)
            } else {
              self.$bvToast.toast(invoice.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });
            }

          })
          .catch(function (error) {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },


    getInvoiceDetailsById(){

      let self = this;

      self.isLoading = true;

      this.getInvoiceById({invoiceId: this.$route.params.id})


        .then(function (invoice) {

          self.isLoading = false;

          self.invoice = invoice;


        })
        .catch(function (error) {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getInvoiceItems(){

      let self = this;

      this.getInvoiceItemsByInvoice({invoiceId: this.$route.params.id, page: this.pagination.current_page})
          .then(function (invoiceItems) {

            self.isLoading = false;

            self.invoiceItems = invoiceItems.content;
            self.pagination.current_page = invoiceItems.number;
            self.pagination.total = invoiceItems.totalElements;
            self.pagination.per_page = invoiceItems.numberOfElements;
            self.pagination.from = invoiceItems.pageable.offset + 1 ;
            self.pagination.to = invoiceItems.pageable.pageSize;
            self.pagination.last_page = invoiceItems.totalPages;
          })
          .catch(function (error) {

            self.isLoading = false;

            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }
          })
    }




  }
};
</script>
